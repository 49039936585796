import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Row,
  Col,
  Dropdown,
  Form,
  Modal,
  ToggleButton,
  ButtonGroup,
  Spinner,
  Button,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import APIServicenew from "../utils/APIGeneralService";
import { formateDate, getCookies, setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';

const PujaRecommendationList = () => {
  document.title = "Puja Recommendation ";
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState("");
  const token = getCookies("accessToken");
  const leadToken = getCookies("accessTokenleads");
  const leadHeaders = { Authorization: `Bearer ${leadToken}` };
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(0);
  const [allSuggestion, setAllSuggestion] = useState([]);
  const [allSuggestionDownload, setAllSuggestionDownload] = useState([]);
  const [puja, setPuja] = useState([]);
  // const selectedPuja = useRef(null);
  const [flag ,setFlag] = useState(false )
  const [notesData, setNotesData] = useState([]);
  const [userForNote, setUserForNote] = useState();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState("");
  const [loader, setLoader] = useState(false);
  const { addToast } = useToasts();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  // for date

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };
  

  const [inputSearch, setInputSearch] = useState({
    userName: "",
    userNumber: "",
    astrologerName: "",
    astrologerNumber: "",

  });
  const [type, setType] = useState("all");
  const [isNotes, setIsNotes] = useState("all");

  const Typedata = useRef({
    type: "",
  });
  const IsNotes = useRef({
    isNotes: "",
  });
  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };
  
  // puja dropdown by react-select 

  const [selectedPuja, setSelectedPuja] = useState({ current: 'all' });
  const Input = props => {
    const { autoComplete = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoComplete} />;
  };
  const pujaOptions = [
    { value: 'all', label: 'All' },
    ...puja.map(item => ({
      value: item._id,
      label: `${item.title} (${moment(item.pujaDate).format('lll')})`,
    })),
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? 'none' : "",
      borderColor: state.isFocused ? '' : "", // or any other color you prefer
    }),
  };

  const handleChangeSec = selectedOption => {
    if (selectedOption) {
      setSelectedPuja({ current: selectedOption.value });
      // handlePujaChangeSec(selectedOption);
    } else {
      console.log('No option selected');
    }
  };

  // for user calling modal
  const [dial, setDail] = useState("Call");
  const [showCallCm, setShowCallCm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };
  const APIServiceschatbot = new APIServicenew(
    process.env.REACT_APP_OTHER_API_URL
  );

  // for astrologer calling modal

  const [dial1, setDail1] = useState("Call");
  const [showCallCm1, setShowCallCm1] = useState(false);
  const [astroId, setAstroId] = useState("");

  const handleCloseCallCm1 = () => {
    setShowCallCm1(false);
  };

  const userCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "puja recommendation",
      },
      leadHeaders
    );

    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };

  const handleConfirm = () => {
    userCall();
  };

  const astrologerCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/callAstrologer",
      {
        astrologerId: astroId,
        source: "puja recommendation",
      },
      leadHeaders
    );
    if (res && res.status === 200) {
      setDail1(res?.data?.message);
      setShowCallCm1(false);
      addToast("Astrologer Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail1("Call");
      }, 10000);
    }
  };

  const handleConfirm1 = () => {
    astrologerCall();
  };
  // const handleInputChange = (e) => {
  //   let fieldName = e.target.name;
  //   let fieldValue = e.target.value;

  //   setInputState((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  // };

  // const [inputState, setInputState] = useState({
  //   startdate: "",
  //   endDate: "",
  // });

  // const handleInputChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  // };
  // const handleInputChangeEnd = date =>{
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, endDate: formattedDate }));
  // }

  

  const handleInputChangeSearch = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;

    setInputSearch((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
  };

  const handleReset = () => {

    // setInputState({
    //   startdate: "",
    //   endDate: "",
    // });
    setStartDate("")
    setEndDate("")
    setSelectedRange("")
    setInputSearch({
      userName: "",
      userNumber: "",
      astrologerName: "",
      astrologerNumber: "",
  
    });
    Typedata.current.type = "all";
    IsNotes.current.isNotes = "all";
    selectedPuja.current = 'all';
    setType("all")
    setIsNotes("all")
    setSkip(0);
    setFlag(true)
  };

  useEffect(()=>{
if (flag === true) {
    fetchedAllSuggestions({ skips: 0 });
}
  },[flag])

  // useEffect(() => {
  //   let pujaId = selectedPuja.current
  //     ? selectedPuja.current === "all"
  //       ? ""
  //       : selectedPuja.current
  //     : "";
  //   let type = Typedata.current.type
  //     ? Typedata.current.type === "all"
  //       ? ""
  //       : Typedata.current.type
  //     : "";
  //   let isNotes = IsNotes.current.isNotes
  //     ? IsNotes.current.isNotes === "all"
  //       ? ""
  //       : IsNotes.current.isNotes
  //     : "";

  //   let startDate = inputState.startdate ? inputState.startdate : "";
  //   let endDate = inputState.endDate ? inputState.endDate : "";

  //   let userName = inputSearch.userName ? inputSearch.userName : "";
  //   let userNumber = inputSearch.userNumber ? inputSearch.userNumber : "";
  //   let astrologerName = inputSearch.astrologerName ? inputSearch.astrologerName : "";
  //   let astrologerNumber = inputSearch.astrologerNumber ? inputSearch.astrologerNumber : "";


  //   fetchedAllSuggestions({
  //     skips: 0,
  //     type: type,
  //     pujaId,
  //     startDate,
  //     endDate,
  //     isNotes: isNotes,
  //     userName,
  //     userNumber,
  //     astrologerName,
  //     astrologerNumber
  //   });
  // }, [inputState ,inputSearch]);

  useEffect(() => {
    fetchedAllSuggestions({ skips: 0 });
    fetchData();
  }, []);

  const handleDownload = (data) => {
    for (let item of data) {
      if (item.userName) {
        item["User Name"] = item.userName;
      } else {
        item["User Name"] = "_";
      }
      // if(item.userNumber){
      //   item['User Number']=item.userNumber
      // }else{
      //   item['User Number']= '_'
      // }
      if (item.astrologerName) {
        item["Astrologer Name"] = item.astrologerName;
      } else {
        item["Astrologer Name"] = "_";
      }
      // if(item.astrologerNumber){
      //   item['Astrologer Number']=item.astrologerNumber
      // }else{
      //   item['Astrologer Number']= "_"

      // }
      if (item.pujaNames) {
        item["Puja Name"] = item.pujaNames.map((puja) => puja.name).join(", ");
      }

      if (item.type) {
        item["Type"] = item.type;
      }

      if (item.createdAt) {
        item["Date "] = moment(item?.createdAt).format("lll");
      }

      delete item._id;
      delete item.createdAt;
      delete item.userName;
      delete item.userNumber;
      delete item.astrologerName;
      delete item.astrologerNumber;
      delete item.pujaNames;
      delete item.isSend;
      delete item.userId;
      delete item.astrologerId;
      delete item.isDeleted;
      delete item.pujaId;
      delete item.orderId;
      delete item.updatedAt;
      delete item.type;
      delete item.__v;
    }
    let csv = Papa.unparse(data, { skipEmptyLines: true });
    let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (window.navigator.msSaveOrOpenBlob)
      window.navigator.msSaveBlob(csvData, "UserList.csv");
    else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(csvData, { type: "text/plain" });
      a.download = "PujaRecommendation.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const columns = [
    {
      dataField: "index",
      text: "No",
      classes: "blocked-column-width",
      formatter: (cell, row, rowIndex) => {
        for (var i = rowIndex; i <= rowIndex + 50; i++) {
          return skip + i + 1;
        }
      },
    },
    {
      dataField: "pujaNames",
      text: "Puja Name",
      formatter: (cell, row) => {
        if (Array.isArray(row?.pujaNames)) {
          return (
            <div>
              {row.pujaNames.map((puja, index) => (
                <div key={index}>{puja.name}</div>
              ))}
            </div>
          );
        } else {
          return null;
        }
      },
    },

    {
      dataField: "userName",
      text: "User Name / Number ",
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {" "}
              {row?.userName}/{row?.userNumber ?? "_"}
            </span><br/>
            <button
              onClick={() => {
                setShowCallCm(true);
                setCurrentUserId(row?.userId);
              }}
              className="mt-2  call_btn btn btn-secondary"
            >
              Call to User
            </button>
          </>
        );
      },
    },
    {
      dataField: "astrologerName",
      text: "Astrologer Name / Astrologer Number",
      formatter: (cell, row) => {
        return (
          <>
            <span>
              {" "}
              {row?.astrologerName}/{row?.astrologerNumber ?? "_"}
            </span>
            <br />
            <button
              onClick={() => {
                setShowCallCm1(true);
                setAstroId(row?.astrologerId);
              }}
              className="mt-2  call_btn btn btn-secondary"
            >
              Call to Astrologer
            </button>
          </>
        );
      },
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell, row) => {
        return row?.type[0]?.toUpperCase() + row?.type.slice(1);
      },
    },
    {
      dataField: "createdAt",
      text: " Date",
    },
    {
      dataField: "userId",
      text: "Notes",

      formatter: (cell, row) => {
        return (
          <h5>
            <input
              type="button"
              className="Et-btn notes-btn"
              value="Notes"
              onClick={() => {
                setUserForNote(row);
                handleUserNotes(row.userId);
              }}
            />
          </h5>
        );
      },
    },
  ];

  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.userId,
        id:userForNote?.pujaNames[0]?.pujaId,
        source:"Leads-Puja-Suggestion",
        name:userForNote?.pujaNames[0]?.name,
        type: "responder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServices.post(
        `userNotes/userNoteMessage`,
        payload,
        leadHeaders
      );

      if (res && res?.data?.status === true) {
        toast.success("Note added successfully");

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        toast.error(res?.data?.message);

        setLoader(false);
      }
      setLoader(false);
    }
  };
  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
  };

  const handleUserNotes = async (id) => {
    const res = await APIServices.get(`userNotes/getDetailById/${id}`, leadHeaders);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };
  const fetchData = async () => {
    let response = await APIServices.get(`astroPooja/pujalisting`, leadHeaders);
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setPuja(apiData.Listdata);
      }
    }
  };
  const suggestionsDownload = async () => {
    let pujaId = selectedPuja.current
      ? selectedPuja.current === "all"
        ? ""
        : selectedPuja.current
      : "";
    let type = Typedata.current.type
      ? Typedata.current.type === "all"
        ? ""
        : Typedata.current.type
      : "";
    let isNotes = IsNotes.current.isNotes
      ? IsNotes.current.isNotes === "all"
        ? ""
        : IsNotes.current.isNotes
      : "";

    let startdate = startDate ? startDate : "";
    let endDate = endDate ? endDate : "";
    let userName = inputSearch.userName ? inputSearch.userName : "";
    let userNumber = inputSearch.userNumber ? inputSearch.userNumber : "";
    let astrologerName = inputSearch.astrologerName ? inputSearch.astrologerName : "";
    let astrologerNumber = inputSearch.astrologerNumber ? inputSearch.astrologerNumber : "";


    const res = await APIServices.get(
      `/lead/poojasuggestionList?userName=${userName}&userNumber=${userNumber}&astrologerName=${astrologerName}&astrologerNumber=${astrologerNumber}&startDate=${startdate}&endDate=${endDate}&type=${type}&pujaId=${pujaId}&isNotes=${isNotes}`,
      leadHeaders
    );
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        handleDownload(resData?.Listdata);
      }
    }
  };

  useEffect(()=>{
    authorizeResponder()
  },[])
  
  const authorizeResponder =async()=>{
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
        {
        },
        leadHeaders
      );
      if (res.data?.status == 4) {
        logout();
      }
    }

  const fetchedAllSuggestions = async ({
    skips = 0,
    type = "",
    pujaId = "",
    isNotes = "",
  }) => {
    // let startdate = startDate;
    // let endDate = endDate;
    let userName = inputSearch.userName;
    let userNumber = inputSearch.userNumber;
    let astrologerName = inputSearch.astrologerName;
    let astrologerNumber = inputSearch.astrologerNumber;


    const res = await APIServices.get(
      `/lead/poojasuggestionList/${skips}/50?userName=${userName}&userNumber=${userNumber}&astrologerName=${astrologerName}&astrologerNumber=${astrologerNumber}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&type=${type}&pujaId=${pujaId}&isNotes=${isNotes}`,
      leadHeaders
    );
    let data = res?.response?.data;

    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData.Listdata];
        setAllSuggestion(
          (newData = newData.map((item) => {
            return {
              _id: item._id,
              pujaNames: item.pujaNames,
              userName: item.userName,
              userNumber: item.userNumber,
              astrologerName: item.astrologerName,
              astrologerNumber: item.astrologerNumber,
              astrologerId: item.astrologerId,
              type: item.type,
              createdAt: moment(item.createdAt).format("lll"),
              userId: item.userId,
            };
          }))
        );
        setUsers(newData);
        setTotalChats(resData.totalCount);
      }
    } else {
      if (data?.status == 4) {
        logout();
      }
    }
  };

  const handleSearch = () =>{
    let pujaId = selectedPuja.current
      ? selectedPuja.current === "all"
        ? ""
        : selectedPuja.current
      : "";
    let type = Typedata.current.type
      ? Typedata.current.type === "all"
        ? ""
        : Typedata.current.type
      : "";
    let isNotes = IsNotes.current.isNotes
      ? IsNotes.current.isNotes === "all"
        ? ""
        : IsNotes.current.isNotes
      : "";

    let startdate = startDate ? startDate : "";
    let enddate = endDate ? endDate : "";
    let userName = inputSearch.userName ? inputSearch.userName : "";
    let userNumber = inputSearch.userNumber ? inputSearch.userNumber : "";
    let astrologerName = inputSearch.astrologerName ? inputSearch.astrologerName : "";
    let astrologerNumber = inputSearch.astrologerNumber ? inputSearch.astrologerNumber : "";


    fetchedAllSuggestions({
      skips: 0,
      type: type,
      pujaId,
      startdate,
      enddate,
      isNotes: isNotes,
      userName,
      userNumber,
      astrologerName,
      astrologerNumber
    });
  }

  const handlePageChange = (selectedPage) => {
    let skipValue = selectedPage.selected * 50;
    setSkip(skipValue);
    let pujaId = selectedPuja.current
      ? selectedPuja.current === "all"
        ? ""
        : selectedPuja.current
      : "";
    let type = Typedata.current.type
      ? Typedata.current.type === "all"
        ? ""
        : Typedata.current.type
      : "";
    let isNotes = IsNotes.current.isNotes
      ? IsNotes.current.isNotes === "all"
        ? ""
        : IsNotes.current.isNotes
      : "";

    let startDate = startDate ? startDate : "";
    let endDate = endDate ? endDate : "";
    let userName = inputSearch.userName ? inputSearch.userName : "";
    let userNumber = inputSearch.userNumber ? inputSearch.userNumber : "";
    let astrologerName = inputSearch.astrologerName ? inputSearch.astrologerName : "";
    let astrologerNumber = inputSearch.astrologerNumber ? inputSearch.astrologerNumber : "";


    fetchedAllSuggestions({
      skips: skipValue,
      type: type,
      pujaId,
      startDate,
      endDate,
      isNotes: isNotes,
      userName,
      userNumber,
      astrologerName,
      astrologerNumber
    });
  };

  const handlePujaChange = (e) => {
    selectedPuja.current = e.target.value;
    // setCookies("skip", 0);
    // setSkip(0);
    // handlingCombineFiltering({
    //   type: Typedata.current.type,
    //   selectedPujaId: e.target.value,
    //   startDate: inputState.startdate ? inputState.startdate : "",
    //   endDate: inputState.endDate ? inputState.endDate : "",
    //   userName : inputSearch.userName ? inputSearch.userName : "",
    //   userNumber : inputSearch.userNumber ? inputSearch.userNumber : "",
    //   astrologerName : inputSearch.astrologerName ? inputSearch.astrologerName : "",
    //   astrologerNumber : inputSearch.astrologerNumber ? inputSearch.astrologerNumber : "",
  
    // });
  };

  // const handlingCombineFiltering = ({
  //   selectedPujaId,
  //   startDate,
  //   endDate,
  //   searchKeyword,
  //   userName,
  //   userNumber,
  //   astrologerName,
  //   astrologerNumber
  // }) => {
  //   let type = Typedata.current.type
  //     ? Typedata.current.type === "all"
  //       ? ""
  //       : Typedata.current.type
  //     : "";
  //   let isNotes = IsNotes.current.isNotes
  //     ? IsNotes.current.isNotes === "all"
  //       ? ""
  //       : IsNotes.current.isNotes
  //     : "";

  //   let pujaId = selectedPujaId
  //     ? selectedPujaId === "all"
  //       ? ""
  //       : selectedPujaId
  //     : "";
  //   setSkip(0);

  //   fetchedAllSuggestions({
  //     skips: skip,
  //     type,
  //     startDate,
  //     endDate,
  //     pujaId,
  //     isNotes,
  //     userName,
  //     userNumber,
  //     astrologerName,
  //     astrologerNumber
  //   });
  // };

  const handleType = async (e) => {
    let type;
    switch (e.target.value) {
      case "all":
        type = "";
        break;
      case "chat":
        type = "chat";
        break;
      case "call":
        type = "call";
        break;
    }
    Typedata.current = { type:type };
    setType(type);
    // let pujaId = selectedPuja.current
    //   ? selectedPuja.current === "all"
    //     ? ""
    //     : selectedPuja.current
    //   : "";
    // let startDate = inputState.startdate ? inputState.startdate : "";
    // let endDate = inputState.endDate ? inputState.endDate : "";
    // let userName = inputSearch.userName ? inputSearch.userName : "";
    // let userNumber = inputSearch.userNumber ? inputSearch.userNumber : "";
    // let astrologerName = inputSearch.astrologerName ? inputSearch.astrologerName : "";
    // let astrologerNumber = inputSearch.astrologerNumber ? inputSearch.astrologerNumber : "";

    // let isNotes = IsNotes.current.isNotes
    //   ? IsNotes.current.isNotes === "all"
    //     ? ""
    //     : IsNotes.current.isNotes
    //   : "";

    // setCookies("skip", 0);
    // fetchedAllSuggestions({ startDate,endDate, skips: skip, type, pujaId, isNotes,userName,
    //   userNumber,
    //   astrologerName,
    //   astrologerNumber });
  };

  const handleIsNotes = async (e) => {
    let isNotes;
    switch (e.target.value) {
      case "all":
        isNotes = "";
        break;
      case "yes":
        isNotes = "yes";
        break;
    }
    IsNotes.current = {isNotes: isNotes };
    setIsNotes(isNotes)
    // let pujaId = selectedPuja.current
    //   ? selectedPuja.current === "all"
    //     ? ""
    //     : selectedPuja.current
    //   : "";
    // let startDate = inputState.startdate ? inputState.startdate : "";
    // let endDate = inputState.endDate ? inputState.endDate : "";
    // let userName = inputSearch.userName ? inputSearch.userName : "";
    // let userNumber = inputSearch.userNumber ? inputSearch.userNumber : "";
    // let astrologerName = inputSearch.astrologerName ? inputSearch.astrologerName : "";
    // let astrologerNumber = inputSearch.astrologerNumber ? inputSearch.astrologerNumber : "";

    // let type = Typedata.current.type
    //   ? Typedata.current.type === "all"
    //     ? ""
    //     : Typedata.current.type
    //   : "";

    // setCookies("skip", 0);
    // fetchedAllSuggestions({
    //   startDate,
    //   endDate,
    //   skips: skip,
    //   type,
    //   pujaId,
    //   isNotes,
    //   userName,
    //   userNumber,
    //   astrologerName,
    //   astrologerNumber
    // });
  };

  
  return (
    <>
      <div className="gita_quote_heading">
        <div className="container d-flex align-items-center justify-content-between">
          <h4 className="hd-list-f font_20 left-q">
            Puja Recommendation <small>({totalChats})</small>
          </h4>
        </div>
      </div>
      <div className="chat_window">
        <Row className="mlist my-3 br-6 p-20">
          <div class="chat_search_box user-search">
         
              <Form.Group as={Row} className="" controlId="">
                <Col lg={3} md={6} className="mb-3">
                  <Form.Label class="label-f-w-cd">User Name</Form.Label>

                  <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="userName"
                    type="text"
                    placeholder="Search By Name"
                    onChange={(e) => {
                      handleInputChangeSearch(e);
                      setSkip(0);
                      setFlag(false)

                    }}
                    value={inputSearch.userName}
                  />
                </Col>

                <Col lg={3} md={6} className="mb-3">
                  <Form.Label class="label-f-w-cd">
                    User Mobile Number
                  </Form.Label>

                  <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="userNumber"
                    type="number"
                    placeholder="Search Mobile Number"
                    onChange={(e) => {
                      handleInputChangeSearch(e);
                      setSkip(0);
                      setFlag(false)

                    }}
                    value={inputSearch.userNumber}
                  />
                </Col>

                <Col lg={3} md={6} className="mb-3">
                  <Form.Label class="label-f-w-cd">
                    Astrologer Name
                  </Form.Label>

                  <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="astrologerName"
                    type="text"
                    placeholder="Search By Name"
                    onChange={(e) => {
                      handleInputChangeSearch(e);
                      setSkip(0);
                      setFlag(false)

                    }}
                    value={inputSearch.astrologerName}
                  />
                </Col>

                <Col lg={3} md={6} className="mb-3">
                  <Form.Label class="label-f-w-cd">
                    Astrologer Mobile Number
                  </Form.Label>

                  <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="astrologerNumber"
                    type="number"
                    placeholder="Search By Astrologer mobile Number"
                    onChange={(e) => {
                      handleInputChangeSearch(e);
                      setSkip(0);
                      setFlag(false)

                    }}
                    value={inputSearch.astrologerNumber}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="" controlId="">
                <Col lg={3} md={6} className="mb-3">
                  <Form.Label class="label-f-w-cd">Puja Name</Form.Label>
                  <Select
                  className="mt-2"
                    components={{ Input }}
                    autoComplete='new-password'
                    options={pujaOptions}
                    value={pujaOptions.find(option => option.value === selectedPuja.current)}
                    tyles={customStyles}
                    onChange={handleChangeSec}
                    classNamePrefix='label-selected-menu'
                    // className='fw-bold text-capitalize custom-datepicker'
                />
                  {/* <Form.Select
                    aria-label="label-selected-menu"
                    className="fw-bold label-selected-menu text-capitalize form-ch w-50"
                    onChange={(e) => {
                      handlePujaChange(e);
                      setSkip(0);
                      setFlag(false)
                    }}
                    value={selectedPuja.current}
                  >
                    <option className="fw-bold" value="all">
                      All
                    </option>
                    {puja.map((item, index) => {
                      return (
                        <>
                          <option className="text-capitalize" value={item._id}>
                            {item.title}
                          </option>
                        </>
                      );
                    })}
                  </Form.Select> */}
                </Col>
                <Col lg={3} md={6} className="mb-3">
                  <Form.Label class="label-f-w-cd">Type</Form.Label>
                  <Form.Select
                    aria-label="label-selected-menu"
                    className="fw-bold label-selected-menu text-capitalize form-ch w-50 mt-2"
                    onChange={(e) => {
                      handleType(e);
                      setSkip(0);
                      setFlag(false)

                    }}
                    // value={Typedata.current.type}
                    value={type}
                    
                  >
                    <option className="fw-bold" value="all">
                      All
                    </option>
                    <option className="text-capitalize" value="chat">
                      Chat
                    </option>
                    <option className="text-capitalize" value="call">
                      Call
                    </option>
                  </Form.Select>
                </Col>

                <Col lg={3} md={6}
                  className=" justify-content-end align-items-end mb-3"
                >
                  <Form.Label class="label-f-w-cd"> Notes</Form.Label>

                  <Form.Select
                    aria-label="label-selected-menu"
                    className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                    onChange={(e) => {
                      handleIsNotes(e);
                      setSkip(0);
                      setFlag(false)

                    }}
                    // value={IsNotes.current.isNotes}
                    value={isNotes}

                  >
                    <option className="fw-bold"> All</option>
                    <option className="text-capitalize" value="yes">
                      Yes{" "}
                    </option>
                    {/* <option className='text-capitalize' value='no'> No </option> */}
                  </Form.Select>
                </Col>

                <Col lg={3} md={6}  className="mb-3">
                <Form.Label class="label-f-w-cd">Select By Date</Form.Label>
                <div className="date-range-wrapper mt-2">
                    <select
                     className="select-range"
                      value={selectedRange}
                      onChange={(e) => handleDateRange(e.target.value)}
                    >
                      <option value="disabled">Select Date Range</option>
                      <option value="today">Today</option>
                      <option value="yesterday">Yesterday</option>
                      <option value="thisWeek">This Week</option>
                      <option value="last7Days">Last 7 Days</option>
                      <option value="thisMonth">This Month</option>
                      <option value="lastMonth">Last Month</option>
                      <option value="customDate">Custom Date</option>
                    </select>

                    {selectedRange === "customDate" && (
                      <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        onChange={(update) => {
                          const [start, end] = update;
                          setStartDate(start);
                          setEndDate(end);
                        }}
                        // isClearable={true}
                        placeholderText="Select custom date range"
                        dateFormat="yyyy-MM-dd"
                      />
                    )}
                  </div>
  
                </Col>
                {/* <Col
                  md={2}
                  className=" justify-content-end  align-items-end"
                >
                  <Form.Label>Start Date</Form.Label>

                 
                  <DatePicker
                    placeholderText={'Select a starting date'}
                    className='form-control form-ch fil-date-pick '
                    name='startdate'
                    selected={inputState.startdate ? new Date(inputState.startdate) : null}
                    onChange={date => {
                      handleInputChange(date);
                      setSkip(0);
                    }}
                    dateFormat='yyyy-MM-dd'
                />
                </Col>
                <Col
                  md={2}
                  className=" justify-content-end align-items-end"
                >
                  <Form.Label>End Date</Form.Label>

                  
                    <DatePicker
                      placeholderText={'Select a ending date'}
                      className='form-control form-ch fil-date-pick '
                      name='startdate'
                      selected={inputState.endDate ? new Date(inputState.endDate) : null}
                     
                      onChange={date => {
                        handleInputChangeEnd(date);
                        setSkip(0);
                    }}
                    dateFormat='yyyy-MM-dd'
                />
                </Col> */}
              </Form.Group>
              <Col lg={3} md={6} className="mb-1 d-flex ps-0 ms-0">
                <Button
                  className="as_btn ms-0"
                  variant="primary"
                  onClick={handleSearch}
                >
                  Filter
                </Button>
                <Button
                  className="as_btn reset-btn"
                  variant="primary"
                  onClick={handleReset}
                >
                  Reset
                </Button>

                {/* <Button
        className='as_btn ms-2 bts '
        variant='primary'
        onClick={() => {
          suggestionsDownload();
        }}
      >
        Download
              </Button> */}
              </Col>
            
          </div>
        </Row>
          {users && users?.length !== 0?<>
          <div className="admin_grid_table table-size border-remove puja-recommendation table-responsive">
            <BootstrapTable keyField="index" data={users} columns={columns} />
          </div>
          {totalChats > 50 && (
            <div className="d-flex justify-content-center align-items-left w-100 p-0 mt-2 custom-pagination">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                breakLabel="..."
                forcePage={skip / 50}
                pageCount={totalChats < 50 ? 1 : Math.ceil(totalChats / 50)} // Total number of pages
                marginPagesDisplayed={2} // Number of pages to display at the start and end of the pagination
                pageRangeDisplayed={1} // Number of pages to display in the middle of the pagination
                onPageChange={handlePageChange} // Callback function when page is changed
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
          </>
          :<h4 className="text-center text-secondary">Not Available</h4>}
      </div>

      {/* for user note  */}
      <Modal
        show={show}
        size="md"
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop="static"
        onHide={handleCloseModal}
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa d-flex">
            <span className="me-3"> Name :- {userForNote?.userName} </span>
            <span> Contact Number :- {userForNote?.userNumber} </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className="my-3" controlId="formAartiInput">
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    name="content"
                    placeholder="Type here..."
                    value={note}
                    style={{ height: 100 }}
                    onChange={(e) => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? (
                    <p className="text-danger">Please enter your note</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
            {loader ? (
              <Button variant="primary" className="as_btn">
                Processing...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="as_btn"
                onClick={handleNotesAdd}
              >
                Add
              </Button>
            )}
            <Button
              variant="secondary"
              className="as_btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>

          {notesData && notesData?.notes?.length > 0 ? (
            <>
              {notesData?.notes?.map((item, index) => (
                <>
                <Row>
                         {item?.source && item?.source != "" ?  
                              <Col xs={5}>
                                  <span className="aa">Source :- </span>
                                    <span className="item-msg">{item.source ? item.source : "" } </span>
                              </Col> : ""
                          }

                          {item?.sourcename && item?.sourcename != "" ? 
                            <Col xs={7}>
                                <span className="aa">Action :- </span>
                                  <span className="item-msg">
                                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                                    </span>
                            </Col> : ""
                          }
                </Row>
                  <Row className="notes-item" key={index}>
                    <Col xs={9}>
                        <span className="item-name aa">
                                Note :- 
                        </span>
                        <span className="item-msg">{item?.message}</span>
                    </Col>
                    <Col xs={3}>
                      <div className="text-end d-flex flex-column">
                        <span className="text-primary item-name aa">
                          {item?.responderName || item?.adminName}
                        </span>
                        <span className="item-text">
                          {item?.createdAt
                            ? moment(item?.createdAt).format("lll")
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <h4 className="text-center text-secondary">Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* user call confirmation */}
      <Modal
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to make a User call?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* astrologer call confirmation */}

      <Modal
        show={showCallCm1}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm1}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to make a Astrologer call?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm1()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={handleCloseCallCm1}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default PujaRecommendationList;
